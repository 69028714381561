import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import './ReCaptcha.scss';

const ReCaptcha = ({ error, onChange, siteKey, recaptchaRef }) => {
  const handleRecaptchaVerify = (value) => {
    onChange(value);
  };

  return (
    <div className={'captcha-wrapper'}>
      <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} onChange={handleRecaptchaVerify} />
      {error && <p className={'captcha-error'}>{error}</p>}
    </div>
  );
};

ReCaptcha.propTypes = {
  error: PropTypes.any,
  onChange: PropTypes.func,
  siteKey: PropTypes.string,
  recaptchaRef: PropTypes.object
};

export default ReCaptcha;
